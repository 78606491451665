<template>  
    <ag-grid-vue  class="ag-grid ag-theme-balham"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          pagination
          :paginationPageSizeSelector="false"
          :paginationPageSize="100"
          :rowData="rowData"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          >
    </ag-grid-vue>
</template>

<script>
import 'ag-grid-enterprise';
import { debounce } from 'lodash';
import { AgGridVue } from 'ag-grid-vue';
import { columnDefSortFunc } from '@/views/management/script/common';
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink';
import CostCellRenderer from '@/components/Aggrid/CellRenderer/Cost';
import StaffTypeCellRenderer from '@/components/Aggrid/CellRenderer/StaffType';
import TaskResourceCellRenderer from '@/components/Aggrid/CellRenderer/TaskResource';
import ArrayCellRenderer from '@/components/Aggrid/CellRenderer/Array';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
// import { fieldValidateUtil } from '@/script/helper-field-validate';
// import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation';
import ColorCellRenderer from '@/components/Aggrid/CellRenderer/Color';
import PayFrequencyRenderer from '@/components/Aggrid/CellRenderer/PayFrequency';

function getColumnDefs(c) {
  return {
    colId: c.colId
    , width: c.actualWidth
    , sort: c.sort != null? c.sort : null
    , sortIndex: c.sortIndex != null? c.sortIndex : null
  }
}

export default {
  name: 'StaffList',
  components: {
    'ag-grid-vue': AgGridVue,
    /* eslint-disable vue/no-unused-components */
    'detailLinkCellRenderer': DetailLinkCellRenderer,
    'costCellRenderer': CostCellRenderer,
    'payFrequencyRenderer': PayFrequencyRenderer,
    'staffTypeCellRenderer': StaffTypeCellRenderer,
    'taskResourceCellRenderer': TaskResourceCellRenderer,
    'arrayCellRenderer': ArrayCellRenderer,
    'dateTimeCellRenderer': DateTimeCellRenderer,
    'colorCellRenderer': ColorCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  props: {
    staffs: { type: Array, default: () => [] },
    sortKey: { type: String, default: null },
    sortOrder: { type: String, default: null },
    columns: { type: Array, default: null }
  },
  data: function() {
    return {
      permissionName: 'STAFF',
      allowManage: true,
      gridOptions: null,
      gridApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
      lastOpenColumnMenuParams: null
    };
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }
        const cols = params.api.getAllGridColumns().map(i => { 
          return { colId: i.colId, headerName: i.colDef.headerName, hide: i.colDef.hide, pinned: i.pinned }} )
        const columnState =  params.api.getColumnState();
        //get the actual hide value from columnState
        for (const col of columnState) {
          const found = cols.find(i => i.colId == col.colId)
          if (found) {
            found.hide = col.hide;
          }
        }
        cols.sort(columnDefSortFunc)
        for (const [index,c] of cols.entries()) {
          params.api.moveColumns([c.colId], index);
        }
        let columns = params.api.getAllDisplayedColumns();
        self.$emit('columnVisible', columns.map(c => getColumnDefs(c)));
      },
      onDragStopped: function(params) {
      
        let columns = params.api.getAllDisplayedColumns();
        self.$emit('columnVisible', columns.map(c => getColumnDefs(c)));
      },
      onColumnResized: debounce(function(params) {
      
        let columns = params.api.getAllDisplayedColumns();
        //self.$emit('columnVisible', columns.map(c => getColumnDefs(c)));
      }, 100),
      onSortChanged: function(/**event*/) {
        const columns = self.gridApi.getAllDisplayedColumns();
        columns.forEach(c => {
          if (c.sort) {
            self.$emit('sortChanged', c.colId, c.sort);
          }
        });
      },
    };
    
    
      // const positionCol = { 
      //   headerName: this.$t('staff.field.position'),
      //   field: 'position',
      //   hide: true,
      //   minWidth: 150
      // }
      
      const locationCol = {
        headerName: this.$t('staff.field.location'),
        field: 'location',
        hide: true,
        minWidth: 150
      }
        
      const departmentCol = {
        headerName: this.$t('staff.field.department'),
        field: 'department',
        hide: true,
        minWidth: 150
      }

      const resourcesCol = {
        headerName: this.$t('staff.field.resources'),
        field: 'resources',
        cellRenderer: 'taskResourceCellRenderer',
        hide: true,
        minWidth: 150
      }

      const skillCol = {
        headerName: this.$t('staff.field.skills'),
        field: 'skills',
        minWidth: 150,
        hide: true
      }

      const payAmountCol = {
        headerName: this.$t('staff.field.payAmount'),
        field: 'payAmount',
        cellRenderer: 'costCellRenderer',
        cellRendererParams: {
          customCurrencyProp: 'currencyCode'
        },
        minWidth: 100,
        hide: true
      }

      const payFrequencyCol = {
        headerName: this.$t('staff.field.payFrequency'),
        field: 'payFrequency',
        cellRenderer: 'payFrequencyRenderer',
        cellRendererParams: {
          getOptions: () => {
            return self.payFrequencyOptions;
          }
        },
        minWidth: 100,
        hide: true
      }

      const payCurrencyCol = {
        headerName: this.$t('staff.field.payCurrency'),
        field: 'payCurrency',
        minWidth: 100,
        hide: true
      }

      const companyCol = {
        headerName: this.$t('staff.field.company'),
        field: 'company',
        minWidth: 100,
        hide: true
      }

      const staffTypeCol = {
        headerName: this.$t('staff.field.type'),
        field: 'staffType',
        cellRenderer: 'staffTypeCellRenderer',
        minWidth: 100,
        hide: true
      }

      const identifierCol = {
        headerName: self.$t('field.identifier_full'),
        field: 'identifier',
        minWidth: 100,
        hide: true
      }

      const emailCol = {
        headerName: self.$t('staff.field.email'),
        field: 'email',
        minWidth: 100,
        hide: true
      }

      const phoneCol = {
        headerName: self.$t('staff.field.phones'),
        field: 'phone',
        minWidth: 100,
        hide: true,
        cellRenderer: 'arrayCellRenderer',
        cellRendererParams: { labelField: 'data' }
      }

      const socialsCol = {
        headerName: self.$t('staff.field.socials'),
        field: 'socials',
        minWidth: 100,
        hide: true,
        cellRenderer: 'arrayCellRenderer',
        cellRendererParams: { labelField: 'data' }
      }

      const websitesCol = {
        headerName: self.$t('staff.field.websites'),
        field: 'websites',
        minWidth: 100,
        hide: true
      }

      const statusCol = {
        headerName: self.$t('staff.field.status'),
        field: 'status',
        minWidth: 100,
        hide: true,
        sortable: false
      }

      const startDateCol = {
        headerName: self.$t('staff.field.startDate'),
        field: 'startDate',
        cellRenderer: 'dateTimeCellRenderer',
        minWidth: 100,
        hide: true
      }

      const endDateCol = {
        headerName: self.$t('staff.field.endDate'),
        field: 'endDate',
        cellRenderer: 'dateTimeCellRenderer',
        minWidth: 100,
        hide: true
      }
      
      const tagCol = {
        headerName: self.$t('staff.field.tag'),
        field: 'tag',
        minWidth: 100,
        hide: true
      }
      
      const colorCol = {
        headerName: self.$t('field.color'),
        field: 'color',
        cellRenderer: 'colorCellRenderer',
        minWidth: 100,
        hide: true
      }
      
    this.columnDefs = [
      {
        headerName: this.$t('staff.field.lastName'),
        field: 'uuId',
        lockVisible: true,
        cellRenderer: 'detailLinkCellRenderer',
        flex: 1,
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if(nodeA.group == nodeB.group) {
            if (valueA === null && valueB === null) {
              return 0;
            }
            if (valueA === null) {
              return -1;
            }
            if (valueB === null) {
              return 1;
            }
            return nodeA.data.lastName.toLowerCase().localeCompare(nodeB.data.lastName.toLowerCase());
          } else if(nodeA.group) {
            return 1;
          } else if(nodeB.group) {
            return -1;
          }
        }
      },
      {
        headerName: this.$t('staff.field.firstName'),
        field: 'firstName',
        flex: 1,
        sort: this.sortKey ? null : 'asc'
      },
      {
        headerName: this.$t('staff.field.position'),
        field: 'position',
        flex: 2
      },
  
      emailCol,
      // positionCol,
      locationCol,
      companyCol,
      departmentCol,
      resourcesCol,
      skillCol,
      payAmountCol,
      payCurrencyCol,
      payFrequencyCol,
      staffTypeCol,
      phoneCol,
      socialsCol,
      websitesCol,
      startDateCol,
      endDateCol,
      identifierCol,
      statusCol,
      tagCol,
      colorCol
    ];

    // Set up initial sort order from settings if we have one
    if (this.sortKey) {
      this.columnDefs.forEach(c => {
        if (c.field == this.sortKey) {
          c.sort = this.sortOrder;
        }
      });
    }

    this.defaultColDef = {
      resizable: true,
      sortable: true,
      minWidth: 100,
      menuTabs: ['columnsMenuTab'],
      valueFormatter: (params) => params.value,
      valueParser: (params) => params.newValue
    };
    this.context = {
      componentParent: self
    };
  },
  beforeDestroy() {
    this.gridApi = null;
  },
  watch: {
    staffs: function(newValue) {
      if(this.gridApi) {
        this.rowData = newValue;
        this.gridApi.redrawRows();
      }
    }
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>${ this.$t('staff.grid.no_data') }</span>`;
    },
    payFrequencyOptions() {
      let options = null;
      if (this.modelInfo != null) {
        const rawOptions = this.modelInfo.find(f => f.field === 'payFrequency').options;
        if (rawOptions != null) {
          options = rawOptions.map(i => {
            return { value: i, text: this.$t(`payFrequency.${i}`) }
          });
        }
      }
      if (options != null) {
        return options;
      }
      return [];
    },
  },
  methods: {
    onGridReady(params) {
      const self = this;
      this.gridApi = params.api;
      this.rowData = this.staffs;
      
      if (this.columns) {
        // order the columns based upon the order in 'columns'
        let idx = 0;
        this.columns.forEach(function(col) {
          const index = self.columnDefs.findIndex((c) => c.field === col.colId);
          if (index !== -1) {
            self.columnDefs.splice(idx++, 0, self.columnDefs.splice(index, 1)[0]);
          }
        });
        
        for (const column of self.columnDefs) {
          const setting = self.columns.filter(c => c.colId === column.field);
          if (setting.length === 0) {
            column.hide = true;
          }
          else {
            column.hide = false;
            column.width = setting[0].width;
            column.sort = setting[0].sort;
            column.sortIndex = setting[0].sortIndex;
          }
        }
        self.gridApi.setGridOption('columnDefs', []);
        self.gridApi.setGridOption('columnDefs', this.columnDefs);
      }
    },
    openDetail(id){
      this.$emit('openStaff', id);
    },
    detailLinkLabel(params) {
      return params.node.data.lastName;
    },
  }
}
</script>
